import { Link, useNavigate } from "react-router-dom";

export default function HomeBanner() {
  const nav = useNavigate();
  return (
    <div className="relative flex justify-center items-center">
      <img
        src={"/banner-mobile.webp"}
        alt="banner"
        className="w-full"
      />
      <div className="absolute font-montserrat sm:w-[600px] w-40 flex flex-col gap-2 sm:gap-2 right-0">
        <p className="sm:text-2xl font-medium text-md dark:text-neutral-900 font-montserrat">
          SPECIAL OFFER
        </p>
        <p className="text-[12px] sm:text-sm dark:text-neutral-900  font-light">
          Enjoy a complimentary Cream & 3 Best Selling
          Samples.
        </p>
        <Link
          to="/"
          className=" sm:w-36 w-28 underline dark:text-neutral-900  text-[12px]  sm:text-[14px]"
        >
          LEARN MORE
        </Link>
      </div>
    </div>
  );
}
