import Subscribe from "../../../src/components/subscribe";
import SocialIcons from "../../../src/components/socialIcons";
import { Link } from "react-router-dom";

export default function Footer() {
  const brandsList = [
    {
      id: 368,
      name: "Kerastase",
    },
    {
      id: 437,
      name: "Schwarzkopf",
    },
  ];
  return (
    <div
      id="footer"
      className="md:pr-32 md:pl-32 pr-10 pl-10 pb-5 flex flex-col lg:flex-row justify-between gap-10 border-t pt-5 dark:border-t-neutral-800 font-montserrat"
    >
      <div className="flex flex-col gap-2 w-full items-start justify-between">
        <div className="flex justify-center items-center sm:justify-start mb-5 w-full">
          <img
            src="/logo.png"
            alt="amadeus-logo"
            className="w-32 dark:invert"
          />
        </div>
        <h6 className="sm:text-md text-sm font-light">
          Subscribe to our newsletter!
        </h6>

        <Subscribe />
      </div>
      <div className="flex flex-col w-full gap-5 items-end">
        <div className="flex flex-wrap justify-between gap-12 w-full pt-3 text-neutral-600 dark:text-neutral-100">
          <div className="flex flex-col gap-5">
            <h6 className="font-montserrat font-medium text-md">
              MENU
            </h6>
            <div className="flex flex-col gap-2 text-[12px]">
              <Link
                to="/"
                className="hover:translate-x-2 duration-300"
              >
                Home
              </Link>
              <Link
                to="/shop"
                className="hover:translate-x-2 duration-300"
              >
                Shop
              </Link>
              <Link
                to="/profile"
                className="hover:translate-x-2 duration-300"
              >
                Account
              </Link>
              <Link
                to="/cart"
                className="hover:translate-x-2 duration-300"
              >
                Cart
              </Link>
              <Link
                to="/wishlist"
                className="hover:translate-x-2 duration-300"
              >
                Wishlist
              </Link>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h6 className="font-montserrat font-medium text-md">
              BRANDS
            </h6>
            <div className="flex flex-col gap-2 text-[12px]">
              {brandsList?.map((brand) => (
                <Link
                  key={brand.id}
                  to={`/shop/category/${
                    brand.id
                  }/${encodeURIComponent(brand.name)}`}
                  className="hover:translate-x-2 duration-300"
                >
                  {brand.name}
                </Link>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <h6 className="font-montserrat font-medium text-md">
              SUPPORT
            </h6>
            <div className="flex flex-col gap-2 text-[12px]">
              <Link
                to={"/"}
                className="hover:translate-x-2 duration-300"
              >
                Privacy Policy
              </Link>
              <Link
                to="/"
                className="hover:translate-x-2 duration-300"
              >
                Terms & Conditions
              </Link>
              <Link
                to="/"
                className="hover:translate-x-2 duration-300"
              >
                Help
              </Link>
            </div>
          </div>
        </div>
        <div className="h-10 flex flex-col justify-end">
          <SocialIcons />
        </div>
      </div>
    </div>
  );
}
