import React from "react";
import MyLoader from "../../components/MyLoader";
import { useCookies } from "react-cookie";
import { useQuery } from "react-query";
import CartDetails from "./cartDetails";
import { CartTable } from "./cartTable";
import { apiCall } from "../../../src/lib/api";

export default function Cart() {
  const [cookies] = useCookies(["cartKey", "userToken"]);

  const { data: cartItems, isLoading } = useQuery(
    ["cartItems"],
    async () => {
      const key = await cookies.cartKey;
      const res = await fetch(
        !cookies.userToken
          ? `${apiCall}/wp-json/cocart/v2/cart/?cart_key=${key}`
          : `${apiCall}/wp-json/cocart/v2/cart/`,
        {
          method: "get",
          headers: {
            "Content-type": "application/json",
            Authorization: !cookies.userToken
              ? ""
              : `Bearer ${cookies.userToken}`,
          },
        }
      );
      if (!res.ok) {
        console.log(`HTTP error: ${res.status}`);
      }
      const response = await res.json();
      return response;
    },
    { enabled: !!cookies.cartKey || !!cookies.userToken }
  );

  return (
    <div className="sm:mt-36 mt-16 p-5">
      <h1 className="text-2xl mb-5 font-montserrat font-medium">
        CART
      </h1>
      <div className="flex w-full gap-10 justify-between sm:flex-row flex-col">
        {isLoading ? (
          <div className="pt-20 w-full flex justify-center items-center">
            {" "}
            <MyLoader />
          </div>
        ) : (
          <CartTable cart={cartItems || []} />
        )}

        <CartDetails loading={isLoading} />
      </div>
    </div>
  );
}
