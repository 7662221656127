import React from "react";
import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel";
import { Button } from "../../../src/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Toaster } from "../../../src/components/ui/toaster";
import { toast } from "../../../src/components/ui/use-toast";
export default function BeautyFactory() {
  const nav = useNavigate();
  const options = {
    loop: true,
    skipSnaps: true,
    duration: 6000,
  };
  const plugin = React.useRef(
    Autoplay({
      stopOnInteraction: true,
      delay: 0,
    })
  );
  const imgs: any = [
    { key: 1, src: "carousel-image-2.jpg" },
    { key: 2, src: "carousel-image-3.jpg" },
    { key: 3, src: "carousel-image-5.jpg" },
    { key: 4, src: "carousel-image-2.jpg" },
    { key: 5, src: "carousel-image-3.jpg" },
    { key: 6, src: "carousel-image-5.jpg" },
  ];
  return (
    <div className="flex justify-between items-center">
      <div className="sm:w-1/2 flex flex-col items-start gap-3 w-full pr-2 lg:pl-32 pl-5">
        <h2 className="sm:text-2xl font-medium text-md font-montserrat  text-center">
          THE BEAUTY FACTORY
        </h2>
        <div className="flex sm:flex-row flex-col sm:items-start items-center gap-2 w-full">
          <Button
            className="sm:w-36 w-28 font-montserrat sm:text-[14px] text-[12px] rounded-none"
            onClick={() => nav("/shop")}
          >
            LEBANON
          </Button>
          <Button
            variant="outline"
            className="sm:w-36 w-28 sm:text-[14px] text-[12px] rounded-none font-montserrat dark:border-neutral-500"
            onClick={() =>
              toast({
                title: "Not available yet",
                className: "fixed top-0 left-0 sm:relative",
              })
            }
          >
            UAE
          </Button>
        </div>
      </div>
      <div className="flex justify-end items-center w-full">
        <Carousel
          plugins={[plugin.current]}
          onMouseEnter={plugin.current.stop}
          onMouseLeave={plugin.current.reset}
          opts={options}
        >
          <CarouselContent>
            {imgs?.map((i: any) => (
              <CarouselItem
                key={i.key}
                className="sm:basis-1/3"
              >
                <img src={i.src} alt="carousel-img" />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
      </div>
    </div>
  );
}
