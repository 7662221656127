import BeautyFactory from "./beautyFactory";
import HomeVideo from "./homeVideo";
import TheArtist from "./theArtist";
import InstaHome from "./instaHome";
import HomeCollections from "./homeCollections";
import HairConcern from "./HairConcern";
import HomeBanner from "./homeBanner";
import HomeProducts from "../products/homeProducts";

export default function UserHome() {
  return (
    <div className="w-full flex flex-col lg:gap-32 gap-10">
      <HomeVideo topImage banner />
      <BeautyFactory />
      <HomeBanner />
      <HomeCollections
        collectionId={368}
        title="Kerastase"
      />
      <HomeCollections
        collectionId={437}
        title="Schwarzkopf"
      />
      <HomeVideo />
      <HairConcern />
      <TheArtist />
      <HomeProducts title="RECOMMENDED BY AMADEUS" />
      {/* <BeforeAfter /> */}
      <InstaHome />
    </div>
  );
}
