import { Button } from "../../../src/components/ui/button";
import { PlayCircle } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./buttonAnimation.css";

export default function HomeVideo({
  banner,
  topImage,
}: {
  banner?: boolean;
  topImage?: boolean;
}) {
  const nav = useNavigate();
  return (
    <div className="relative flex justify-center items-center">
      {banner ? (
        <div className="relative w-full">
          <img
            src={"/header.jpeg"}
            alt="banner"
            className="w-screen"
          />
          <div className="absolute sm:bottom-80 bottom-10 sm:right-5 right-20 lg:right-64 flex flex-col sm:items-start items-center gap-3 text-neutral-200 ">
            <p className="font-montserrat font-medium sm:text-2xl text-sm sm:text-left text-center pr-2 dark:text-neutral-200  w-48 sm:w-full">
              PERSONALIZE YOUR HAIR EXPERIENCE
            </p>
            <p className="w-48 sm:w-[370px] font-montserrat font-light sm:text-[16px] text-[12px]  hidden sm:block dark:text-neutral-200 ">
              Everyone wants exceptional hair, but the
              solution is not the same for everyone.
            </p>
            <Button
              onClick={() => nav("/shop")}
              className="b-button sm:w-36 w-28 font-montserrat sm:text-[14px] text-[12px] rounded-none dark:text-neutral-200 dark:bg-neutral-900"
            >
              <div className="b-fancy"></div>
              <span className="b-text">SHOP NOW</span>
            </Button>
          </div>
        </div>
      ) : (
        <video
          width="100%"
          height="100%"
          autoPlay
          loop
          poster={"/carousel-4.png"}
          muted
        >
          <source src="/hv.m4v" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {/* { <div className="w-full absolute h-full bg-neutral-900  opacity-50"></div>} */}
    </div>
  );
}
