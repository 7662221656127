import { Instagram } from "lucide-react";
import React from "react";

export default function InstaHome() {
  return (
    <div className="w-full">
      <div className="dark:bg-neutral-100 bg-neutral-900  w-full dark:text-neutral-900  text-neutral-100 flex justify-center items-center p-2 font-montserrat gap-2 sm:gap-5">
        <Instagram className="w-4 h-4" />
        <span className="sm:text-md text-sm">
          FOLLOW US ON INSTAGRAM
        </span>
      </div>
    </div>
  );
}
