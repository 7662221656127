import React from "react";
import { CategoriesDrawer } from "../../components/categoriesDrawer";
import { CartDrawer } from "../../components/cartDrawer";
import { User } from "lucide-react";
import { Link } from "react-router-dom";
import { ModeToggle } from "../../../src/components/themeToggle";
import { UserMenu } from "./userMenu";
import CategoriesBar from "./categoriesBar";
import Wishlist from "../../../src/components/Wishlist";
import useWishlist from "../../../src/hooks/useWishlist";
import SearchSheet from "../../../src/components/searchSheet";

export default function Navbar() {
  const [switchLogo, setSwitchLogo] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [lastScrollY, setLastScrollY] = React.useState(0);
  const { data: wishlist } = useWishlist();
  const handleShowMenu = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY) {
      // Scroll down
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
    setLastScrollY(currentScrollY);
  };
  const handleSwitchLogo = () => {
    if (window.scrollY > 100) {
      // Scroll down

      setSwitchLogo(true);
    } else {
      setSwitchLogo(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener("scroll", handleShowMenu);
    window.addEventListener("scroll", handleSwitchLogo);

    return () => {
      window.removeEventListener("scroll", handleShowMenu);
      window.removeEventListener(
        "scroll",
        handleSwitchLogo
      );
    };
  }, [lastScrollY]);
  const location = window.location.pathname;

  return (
    <div
      className={
        location === "/" && !switchLogo
          ? "fixed top-0 bg-transparent dark:bg-neutral-900 z-50 w-full bg-gradient-to-t from-[#ffffff10] to-[#00000090]"
          : "fixed top-0 bg-white dark:bg-neutral-900 z-50 w-full border-b "
      }
    >
      <div
        className={
          switchLogo
            ? "sm:pl-2 sm:pr-2 p-1 flex w-screen justify-between items-center bg-white dark:bg-neutral-900"
            : "sm:pl-2 sm:pr-2 p-1 flex w-screen justify-between items-center"
        }
      >
        <div className="flex justify-start items-center gap-1 sm:w-full">
          <CategoriesDrawer
            scrolled={switchLogo || location !== "/"}
          />

          <Link
            to="/"
            className={
              switchLogo || location !== "/"
                ? " flex justify-start items-center"
                : " flex justify-end items-center"
            }
          >
            <img
              src="/logo-aaa.png"
              alt="logo"
              className={
                switchLogo && showMenu
                  ? "opacity-100 duration-500 dark:invert w-8"
                  : "opacity-0 pointer-events-none cursor-auto translate-x-96 duration-500 dark:invert w-8"
              }
            />
          </Link>
        </div>
        <Link
          to="/"
          className="flex sm:justify-center items-center sm:w-full"
        >
          <img
            src="/logo-6.png"
            alt="center-logo"
            className={
              switchLogo && showMenu
                ? "opacity-0 pointer-events-none duration-300 dark:invert sm:pt-3 -translate-x-48 w-64"
                : `opacity-100 duration-300 sm:pt-3 -translate-x-5 w-64 ${
                    location === "/" && !switchLogo
                      ? "invert"
                      : "dark:invert"
                  }`
            }
          />
        </Link>
        <div className="flex sm:gap-3 gap-2 hover:cursor-pointer sm:w-full justify-end items-center">
          <SearchSheet
            scrolled={switchLogo || location !== "/"}
          />
          <UserMenu
            scrolled={switchLogo || location !== "/"}
          />

          <Wishlist
            navBar
            scrolled={switchLogo || location !== "/"}
            wishlist={wishlist}
          />

          <Link
            to="/profile"
            className={
              switchLogo || location !== "/"
                ? "sm:block hidden  text-neutral-900  hover:fill-neutral-900  dark:text-neutral-200 hover:fill-neutral-100"
                : "sm:block hidden  text-neutral-100 hover:fill-neutral-200 dark:text-neutral-200 hover:fill-neutral-100"
            }
          >
            <User
              className={
                switchLogo || location !== "/"
                  ? "w-5 h-5 hover:fill-neutral-900 dark:hover:fill-neutral-200"
                  : "w-5 h-5 hover:fill-neutral-200 dark:hover:fill-neutral-200"
              }
            />
          </Link>
          <CartDrawer
            scrolled={switchLogo || location !== "/"}
          />
          <div className="sm:block hidden">
            <ModeToggle
              scrolled={switchLogo || location !== "/"}
            />
          </div>
        </div>
      </div>
      <div
        className={
          location !== "/" || switchLogo
            ? `dark:bg-neutral-900 dark:text-neutral-200 bg-white  font-montserrat ${
                showMenu ? "hidden" : "lg:block hidden"
              }`
            : ` lg:block hidden font-montserrat `
        }
      >
        <CategoriesBar
          scrolled={switchLogo || location !== "/"}
        />
      </div>
    </div>
  );
}
