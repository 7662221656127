import { LoaderIcon } from "lucide-react";
import React from "react";
import { Skeleton } from "./ui/skeleton";

export default function MyLoader({
  skeleton,
}: {
  skeleton?: boolean;
}) {
  return !skeleton ? (
    <div className="flex justify-center w-full h-full items-center">
      <img
        src="/logo-aaa.png"
        className="animate-ping w-8 dark:invert"
      />
    </div>
  ) : (
    <div className="flex flex-col items-center space-y-4">
      <Skeleton className="h-4 sm:w-[250px] w-32" />
      <Skeleton className="h-4 sm:w-[200px] w-24" />
    </div>
  );
}
