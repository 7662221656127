import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "next-themes";
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Login from "./pages/login/Login";
import Profile from "./pages/profile/Profile";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";
import ErrorPage from "./pages/error404/errorPage";
import UserHome from "./pages/userLayout/UserHome";
// import VideoDetails from "./pages/videoDetails/VideoDetails";
import UserLayout from "./pages/userLayout/UserLayout";
// import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
// import AdminLayout from "./pages/adminLayout/AdminLayout";
// import AdminProfile from "./pages/adminLayout/AdminProfile";
import Wishlist from "./pages/wishlist/WishlistPage";
import Product from "./pages/singleProduct/product";
import Cart from "./pages/cart/Cart";
import Checkout from "./pages/checkout/checkout";
import Order from "./pages/checkout/order";
import Signup from "./pages/signup/signup";
import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
import LostPassword from "./pages/lostPassword/LostPassword";
import ResetPassword from "./pages/lostPassword/resetPass";
import { Toaster } from "./components/ui/toaster";
import ShopPage from "./pages/shop/ShopPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <UserLayout />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      { path: "admin", element: <AdminDashboard /> },
      { path: "", element: <UserHome /> },
      { path: "profile", element: <Profile /> },

      {
        path: "/product/:categoryId/:categoryName/:productId/:productName",
        element: <Product />,
      },
      {
        path: "/product/:productId/:productName",
        element: <Product />,
      },
      { path: "/wishlist", element: <Wishlist /> },
      { path: "/cart", element: <Cart /> },
      { path: "/shop", element: <ShopPage /> },
      {
        path: "/shop/hairType/:tagId/:tagName",
        element: <ShopPage />,
      },
      {
        path: "/shop/category/:categoryId/:categoryName",
        element: <ShopPage />,
      },
      { path: "/checkout", element: <Checkout /> },
      { path: "order/:orderId", element: <Order /> },
      { path: "reset", element: <LostPassword /> },
      {
        path: "reset_pass/:email",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider
      attribute="class"
      defaultTheme="light"
      enableSystem
      disableTransitionOnChange
    >
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
