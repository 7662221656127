import React from "react";
import Navbar from "../navBar/navbar";
import { Outlet } from "react-router-dom";
import Footer from "../footer/Footer";
import ScrollToTop from "../../../src/components/scrollToTop";

export default function UserLayout() {
  return (
    <div className="flex flex-col justify-between h-screen">
      <ScrollToTop />
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
}
