import React from "react";
import { wooProduct } from "./productInterface";
import { Link, useNavigate } from "react-router-dom";
import AddToCart from "../../../src/components/addToCart";
import {
  Card,
  CardHeader,
  CardContent,
  CardDescription,
  CardFooter,
} from "../../../src/components/ui/card";
import { Skeleton } from "../../../src/components/ui/skeleton";
import Wishlist from "../../components/Wishlist";
import MyLoader from "../../../src/components/MyLoader";
import { productCategoryFilter } from "../../../src/lib/utils";
import useWishlist from "../../../src/hooks/useWishlist";

export default function Products({
  products,
  title,
}: {
  products: wooProduct[];
  title?: string;
}) {
  const nav = useNavigate();
  const { data: wishlist } = useWishlist();
  return (
    <div className="flex-col flex gap-5">
      <h2 className="font-montserrat font-medium sm:text-2xl text-md text-center lg:text-left">
        {title}
      </h2>
      <div
        className={
          "flex gap-y-10 sm:justify-around gap-x-1 sm:gap-x-5 no-scrollbar sm:overflow-hidden flex-wrap w-full"
        }
      >
        {!products ? (
          <div className="w-full h-full">
            <MyLoader />
          </div>
        ) : (
          products?.map((product: wooProduct, index) => (
            <Card
              className="rounded-none font-montserrat w-[49%] sm:w-[46%] lg:w-60 snap-always snap-center justify-between relative flex flex-col gap-2 dark:border-neutral-800 dark:border cursor-pointer"
              key={index}
            >
              <div className="absolute top-4 right-4">
                <Wishlist
                  productId={product.id}
                  productName={product.name}
                  wishlist={wishlist}
                />
              </div>

              {productCategoryFilter(product?.categories)
                .slice(0, 1)
                .map((category) => (
                  <div
                    onClick={() =>
                      nav(
                        `/product/${category.id}/${category.name}/${product.id}/${product.slug}`
                      )
                    }
                    key={category.id}
                    className={
                      product.images
                        ? "w-full flex justify-center items-center sm:p-5 p-1 dark:bg-white grow h-1/4"
                        : "w-full flex justify-center items-center sm:p-5 p-1 dark:bg-neutral-900 grow h-1/4"
                    }
                  >
                    <img
                      src={
                        product?.images[0]?.src
                          ? product?.images[0]?.src
                          : "/logo.png"
                      }
                      alt=""
                      className={
                        product?.images[0]?.src
                          ? "w-24 sm:w-36"
                          : "dark:invert w-24 sm:w-36"
                      }
                    />
                  </div>
                ))}

              <CardHeader
                className=" w-full flex flex-col p-0 m-0 flex flex-col gap-2 sm:pl-3 sm:pr-3 pl-1 pr-1 items-start"
                onClick={() =>
                  nav(
                    `/product/${product.categories[1].id}/${product.categories[1].name}/${product.id}/${product.slug}`
                  )
                }
              >
                {products ? (
                  <p className="truncate w-full font-medium text-sm sm:text-md">
                    {product?.name.toUpperCase()}
                  </p>
                ) : (
                  <Skeleton className="h-4 w-32" />
                )}
              </CardHeader>
              <CardContent className="sm:text-sm text-[12px] sm:pl-3 sm:pr-3 pl-1 pr-1 flex flex-col gap-1 pb-0 ">
                {productCategoryFilter(product?.categories)
                  .slice(0, 1)
                  .map((category) => (
                    <Link
                      key={category.id}
                      to={`/shop/category/${
                        category.id
                      }/${encodeURIComponent(
                        String(category.name)
                      )}`}
                      className="text-neutral-400 italic hover:translate-x-2 duration-300 truncate"
                    >
                      {category.name}
                    </Link>
                  ))}
                <Link
                  to={`/shop/hairType/${
                    product.tags[0]?.id
                  }/${encodeURIComponent(
                    String(product.tags[0]?.name)
                  )}`}
                  className="text-neutral-400 italic sm:text-sm text-[12px] hover:translate-x-2 duration-300 truncate"
                >
                  {product.tags[0]?.name}
                </Link>
                <CardDescription className="truncate w-full dark:text-neutral-200 text-neutral-900 sm:text-sm text-[12px]">
                  {product.description.length > 0
                    ? product?.description.slice(3, -5)
                    : "..."}
                </CardDescription>
                <p className="sm:text-xl text-[16px] font-bold grow">
                  {product?.price}$
                </p>
              </CardContent>
              <CardFooter className=" sm:p-3 p-1">
                <AddToCart
                  id={String(product.id)}
                  quantity={"1"}
                  name={product.name}
                  categoryId={product.categories[0]?.id}
                  categoryName={product.categories[0]?.name}
                  hasVariations={
                    product?.attributes &&
                    product?.attributes.length > 0
                      ? true
                      : false
                  }
                />
              </CardFooter>
            </Card>
          ))
        )}
      </div>
    </div>
  );
}
